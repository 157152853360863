<style scoped>

</style>

<template>
    <Aggrid
        apiRoute='parameter/list'
        :columnDefs="columnDefs" 
        :frameworkComponents="frameworkComponents"
        :context="context"
        aggridHeightSubstract="40px"
    />
    <Modal v-show="modalEditParameterDisplayed">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'edit']" />
        </template>
        <template v-slot:modalTitle>
            <div>MODIFIER UN PARAMÈTRE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput 
                        v-model="parameter.label"
                        type="text" 
                        label="Libellé"
                        :disabled="true"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="parameter.value"
                        type="textarea" 
                        label="Valeur"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalEditParameter" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModalEditParameter" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
</template>

<script>
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ListParametersActionButtonsRenderer from './ListParametersActionButtonsRenderer';
    import BaseInput from '../../components/Base/BaseInput.vue';
    import BaseButton from '../../components/Base/BaseButton.vue';
    import Modal from '../../components/App/Modal.vue';
    import axios from 'axios';

    export default {
        name: 'Suppliers',
        components: {
            Aggrid,
            BaseInput,
            BaseButton,
            Modal
        },
        data() {
            return {
                columnDefs: [
                    { field: 'label', headerName : `Libellé`, flex: 1, lockPosition: true },
                    { field: 'value', headerName : `Valeur`, flex: 1, lockPosition: true },
                    { headerName : '', width: 220, cellRenderer: 'ListParametersActionButtonsRenderer', suppressMenu: true }
                ],
                frameworkComponents: {
                    ListParametersActionButtonsRenderer: ListParametersActionButtonsRenderer,
                },
                context: null,
                modalEditParameterDisplayed: false,
                parameter: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetParameter();
        },
        methods: {
            resetParameter() {
                this.parameter = {
                    id: null,
                    label: null,
                    value: null
                }
            },
            displayEditParameterModal(parameter) {
                this.parameter = parameter;
                this.modalEditParameterDisplayed = true;
            },
            closeModalEditParameter() {
                this.resetParameter();
                this.modalEditParameterDisplayed = false;
            },
            formateParameter(parameter) {
                return {
                    id: parameter.id,
                    value: parameter.value
                };
            },
            submitModalEditParameter() {
                axios
                .put('parameter/update', this.formateParameter(this.parameter), { 
                    toastSuccessMessage: `Paramètre édité`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModalEditParameter();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            }
        }
    }
</script>